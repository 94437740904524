<template>
  <Fragment></Fragment>
</template>

<script>
export default {
  async beforeMount () {
    console.log('Logging out...')

    try {
      await this.$store.dispatch('auth/signOut')
    } catch (error) {
      // If an error occurs during signout we can't really do much about it.
      // So just fail silently and continue.
    } finally {
      this.$router.push('/signin')
    }
  }
}
</script>
